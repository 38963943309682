<template>
  <div class="main-body">
    <a-table
      rowKey="menu_id"
      :columns="columns"
      :data-source="data"
      :pagination="Refpagination"
      @change="handleRefresh"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex=='status'">
          <span v-if="record.status==1">显示中</span>
          <span v-if="record.status==0">未显示</span>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showFromModal(record)">回复</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)"
              >删除</a
            >
            <a-divider type="vertical" />
            <a @click="changeStatus(record,0)" v-if="record.status==1">设为不显示</a>
            <a @click="changeStatus(record,1)" v-if="record.status==0">设为显示</a>
          </span>
        </template>
      </template>
      
    </a-table>
  </div>
  <a-modal
      v-model:visible="visible"
      title="回复"
      @ok="handleSubmit"
      @cancel="onCancel"
      :width="720"
  >
    <a-form ref="formRef" :model="formState" >
      <a-form-item has-feedback label="回复内容" name="reply_info">
        <a-textarea
            v-model:value="formState.reply_info"
            placeholder="请输入回复内容"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {createVNode, defineComponent, reactive, ref, onMounted} from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/addons/user/api";
const columns = [
  {
    title: "来信人",
    dataIndex: "username",
    width: "10%",
  },
  {
    title: "信件主题",
    dataIndex: "title",
    width: "10%",
  },
  {
    title: "内容",
    dataIndex: "summary",
  },
  {
    title: "类型分组",
    dataIndex: "type",
    width: "10%",
  },
  {
    title: "显示状态",
    dataIndex: "status",
    width: "10%",
  },
  {
    title: "回复状态",
    dataIndex: "hf_status",
    width: "10%",
  },
  {
    title: "来信时间",
    dataIndex: "create_time",
    width: "16%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {

  },
  setup() {
    const visible = ref(false);
    const formRef = ref();
    const showFromModal = (record) => {
      formState.id=record.id
      formState.reply_info=record.reply_info
      visible.value = true;
    };

    const handleSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            Api.message_replay(formState).then(
                (res) => {
                  if(res.status==200){
                    message.success(res.message);
                    visible.value = false;
                    handleRefresh();
                  }else{
                    message.error(res.message);
                  }

                },
                (error) => {
                  message.error(error);
                }
            );
          })
          .catch((error) => {
            console.log("error", error);
          });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    const formState = reactive({
      id:0,
      reply_info: "",
    });

    const data = ref([]);
    const Refpagination = ref({});


    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if(typeof pagination !=='undefined'){
        formState.page = pagination.current
      }
      Api.message_list(formState).then((res) => {
        Refpagination.value.current = res.data.list.current_page;
        Refpagination.value.pageSize = res.data.list.per_page;
        Refpagination.value.total = res.data.list.total;
        data.value = res.data.list.data;
      });
    };
    const changeStatus=(record,status)=>{

      Modal.confirm({
        title: "确定进行此操作吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.message_change({ id: record.id,status:status }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    }
    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.message_delete({ id: record.id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();

    });

    return {
      visible,
      formRef,
      formState,
      columns,
      data,
      Refpagination,
      showDeleteConfirm,
      handleRefresh,
      showFromModal,
      handleSubmit,
      onCancel,
      changeStatus
    };
  },
});
</script>